jQuery(function($){

    /** Menu Trigger **/
    $('.menu-trigger').click(function(){
       $('.main-navigation').toggleClass('open');
        $('.menu-trigger i').toggleClass('sc-menu sc-cancel');
    });

    /** Testimonial Slider **/
    $('.testimonial-slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.testimonial-slider-nav'
    });

    $('.testimonial-slider-for').show();

    $('.testimonial-slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: '.testimonial-slider-for',
        focusOnSelect: true,
    });

    $('.testimonial-slider-nav').show();

    /** Product Slider **/
    $('.product-image-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true
    });

    $('.product-image-slider').show();

    $('.slider-custom-controls .slider-next').click(function(){
        var slickSlider = $(this).closest('.slider-custom-controls').find('.slick-slider');
        slickSlider.slick('slickNext');
    });

    $('.slider-custom-controls .slider-prev').click(function(){
        var slickSlider = $(this).closest('.slider-custom-controls').find('.slick-slider');
        slickSlider.slick('slickPrev');
    });

    /** Feature Link **/
    if ($('a').hasClass('feature-link')) {
        $('.feature-link').append('<i class="sc-right-open"></i>');
    }


    setFooterPosition();

    // matchHeight();
    //
    // setTimeout(function(){
    //     matchHeight();
    // }, 250);

    $('.match-height').matchHeight();
});

/** Sticky Footer **/
function setFooterPosition() {
  var footerEl = $('#footer');
  var windowHeight = $(window).height();
  var topHeight = $('#top').height();
  var footerHeight = footerEl.outerHeight(true);

  if (topHeight + footerHeight > windowHeight) {
    footerEl.removeClass('bottom');
  } else {
    footerEl.addClass('bottom');
  }
}

/** Match Height **/
// function matchHeight() {
//     /** Match Height **/
//     $('.match-height').matchHeight({
//         byRow: true,
//         property: 'min-height',
//         target: null,
//         remove: false
//     });
//
//     $('.nav-drop-item').matchHeight({
//         byRow: true,
//         property: 'min-height',
//         target: null,
//         remove: false
//     });
// }

jQuery(window).load(function($) {
    setFooterPosition();
    //matchHeight();

});

jQuery(window).resize(function($) {
    setFooterPosition();
    //matchHeight();
});
